<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push('/tabs/clubs')"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="data.name"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" pull-factor="0.5" pull-min="100" pull-max="200" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div class="avatar-container">
        <avatar :value="data.logo_tn" :initials="initials"/>
      </div>
      <ion-item>
        <ion-label v-text="__('interface.name')"></ion-label>
        <ion-note slot="end" v-text="data.name"></ion-note>
      </ion-item>
      <ion-list>
        <ion-item @click="$router.push(`/tabs/clubs/${$route.params.id}/trainers`)">
          <ion-label v-text="__('interface.trainers')"></ion-label>
          <ion-icon :icon="chevronForwardOutline" slot="end" size="small"></ion-icon>
        </ion-item>
        <ion-item @click="$router.push(`/tabs/clubs/${$route.params.id}/sessions`)">
          <ion-label v-text="__('interface.sessions')"></ion-label>
          <ion-icon :icon="chevronForwardOutline" slot="end" size="small"></ion-icon>
        </ion-item>
        <ion-item @click="$router.push(`/tabs/clubs/${$route.params.id}/history`)">
          <ion-label v-text="__('interface.history')"></ion-label>
          <ion-icon :icon="chevronForwardOutline" slot="end" size="small"></ion-icon>
        </ion-item>
      </ion-list>
      <ion-button color="danger" expand="full" class="ion-margin-vertical" @click="actionLeaveClub">
        {{ __('interface.leave-club') }}
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonRefresher,
  IonRefresherContent,
  loadingController,
  IonIcon,
  IonNote,
  actionSheetController
} from '@ionic/vue'
import {chevronForwardOutline} from 'ionicons/icons';
import {API} from "@/utils/apiWrapper";
import Avatar from "../../components/Avatar";

export default {
  name: "ClubView",
  components: {Avatar, IonPage, IonRefresherContent, IonRefresher, IonIcon, IonNote},
  setup() {
    return {
      chevronForwardOutline
    }
  },
  computed: {
    initials() {
      let initials = ''
      const name = this.data.name ?? ''

      name.split(' ').forEach(word => {
        initials += word.substring(0, 1)
      })

      return initials
    }
  },
  data() {
    return {
      data: {}
    }
  },
  methods: {
    doRefresh(event) {
      this.loadData().finally(() => {
        event.target.complete()
      })
    },
    loadData() {
      return API.get('TheSpottApi', '/club', {
        queryStringParameters: {
          id: this.$route.params.id
        }
      }).then(response => {
        this.data = response.data
      })
    },
    async actionLeaveClub() {
      const actionSheet = await actionSheetController.create({
        header: this.__('interface.options'),
        buttons: [
          {
            text: this.__('interface.leave-club'),
            role: 'destructive',
            handler: () => {
              this.doLeaveClub()
            }
          },
          {
            text: this.__('interface.cancel'),
            role: 'cancel'
          }
        ],
      });
      return actionSheet.present();
    },
    doLeaveClub() {
      API.del('TheSpottApi', '/club-customer', {
        queryStringParameters: {
          clubId: this.$route.params.id
        }
      }).then(() => {
        this.$router.push({name: 'ClubIndex'})
        this.$tracking.event({
          eventName: 'leave-club',
          category: 'clubs',
          event: {
            clubId: this.$route.params.id
          }
        });
      })
    }
  },
  async ionViewWillEnter() {
    const loading = await loadingController.create({
      message: this.__('interface.loading'),
    })
    await loading.present()

    this.loadData().finally(() => {
      loading.dismiss()
    })
  }
}
</script>

<style lang="scss">
.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  ion-avatar {
    height: 72px;
    width: 72px;

    .blank-logo {
      font-size: 24px;
    }
  }
}
</style>